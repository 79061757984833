import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material
import { styled } from '@mui/material/styles';
import { Box, Toolbar, Button } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { IntlActions, useTranslate } from 'react-redux-multilingual';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';

// components

import LangSwitch from '../../components/LangSwitch';

import './Header.css';

import Hamburger from 'hamburger-react';
import { StyledWriteButton } from '../../components/StyledComponents';
import Draw from '../../assets/write.png';
//

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled('header')(({ theme }) => ({
  boxShadow: 'none',
  background:
    'linear-gradient(180deg, #ffffff 0%, rgb(255 255 255) 30.73%, rgb(255 255 255 / 67%) 72.92%, rgb(255 255 255 / 0%) 100%)',
  [theme.breakpoints.up('lg')]: {
    width: `100%`
  },
  position: 'fixed',
  zIndex: '99',
  maxWidth: '1440px',
  width: '100%'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));
const StyledButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'rgb(42 124 85 / 90%)'
  },
  transition: 'all 0.5s',
  cursor: 'pointer',
  width: 'max-content',
  minWidth: '0px',
  color: theme.palette.common.black,
  fontWeight: '400',
  fontSize: '24px',
  lineHeight: '36px',
  textTransform: 'inherit',
  fontFamily: 'Noto Sans',
  textAlign: 'center',
  [theme.breakpoints.up('xs')]: {
    fontSize: '20px',
    lineHeight: '24px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '24px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '24px',
    lineHeight: '36px'
  }
}));

// ----------------------------------------------------------------------

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

function Navbar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslate();
  const [isLang, setIsLang] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [screenType, setScreenType] = useState('desktop');
  useEffect(() => {
    if (props.language === 'ua') {
      setIsLang(false);
    } else {
      setIsLang(true);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (window.screen.width <= 1024) {
      setScreenType('mobile');
    }
  }, []);

  document.addEventListener('click', (e) => {
    e.stopPropagation();
    const className = e.target.className;
    if (openMenu && className && className !== 'header-menu' && className !== 'hamburger-react') {
      setOpenMenu(false);
    }
  });

  return (
    <RootStyle>
      <ToolbarStyle>
        <div className="header-burger-button">
          <Hamburger toggled={openMenu} toggle={setOpenMenu} size={24} hideOutline={true} />
        </div>

        <Link
          to="top"
          className="header-logo"
          spy={true}
          smooth={true}
          offset={screenType === 'desktop' ? -200 : -400}
          duration={1000}
          onClick={(e) => setOpenMenu(false)}
        />

        <Box className={`header-menu ${openMenu ? 'header-openMenu' : ''}`}>
          <StyledButton disableRipple>
            <Link
              style={{ textAlign: 'center', fontSize: '18px' }}
              to="devops"
              spy={true}
              smooth={true}
              offset={screenType === 'desktop' ? -150 : -80}
              duration={1000}
              onClick={(e) => setOpenMenu(false)}
            >
              {translate('visitCard_process_link')}
            </Link>
          </StyledButton>
          <StyledButton disableRipple>
            <Link
              style={{ textAlign: 'center', fontSize: '18px' }}
              to="services"
              spy={true}
              smooth={true}
              offset={screenType === 'desktop' ? -150 : -200}
              duration={1000}
              onClick={(e) => setOpenMenu(false)}
            >
              {translate('visitCard_technologies_link')}
            </Link>
          </StyledButton>
          <StyledButton disableRipple>
            <Link
              style={{ textAlign: 'center', fontSize: '18px' }}
              to="about"
              spy={true}
              smooth={true}
              offset={screenType === 'desktop' ? 150 : 50}
              duration={1000}
              onClick={() => setOpenMenu(false)}
            >
              {translate('visitCard_about_link')}
            </Link>
          </StyledButton>
          <StyledButton disableRipple>
            <Link
              style={{ textAlign: 'center', fontSize: '18px' }}
              to="solutions"
              spy={true}
              smooth={true}
              offset={screenType === 'desktop' ? -150 : -220}
              duration={1000}
              onClick={() => setOpenMenu(false)}
            >
              {translate('visitCard_solution_link')}
            </Link>
          </StyledButton>
          <StyledButton disableRipple>
            <Link
              style={{ textAlign: 'center', fontSize: '18px' }}
              to="FAQ"
              spy={true}
              smooth={true}
              offset={screenType === 'desktop' ? -100 : -70}
              duration={1000}
              onClick={() => setOpenMenu(false)}
            >
              FAQs
            </Link>
          </StyledButton>
          <StyledButton>
            <Link
              style={{ textAlign: 'center', fontSize: '18px' }}
              to="Cooperation"
              spy={true}
              smooth={true}
              offset={screenType === 'desktop' ? -150 : -80}
              duration={1000}
              onClick={() => setOpenMenu(false)}
            >
              {translate('visitCard_cooperation_link')}
            </Link>
          </StyledButton>
          <StyledButton>
            <Link
              style={{ textAlign: 'center', fontSize: '18px' }}
              to="contact"
              spy={true}
              smooth={true}
              offset={50}
              duration={1000}
              onClick={() => setOpenMenu(false)}
            >
              {translate('visitCard_contact_link')}
            </Link>
          </StyledButton>
        </Box>
        <div
          style={{
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <LangSwitch
            isOn={isLang}
            handleToggle={() => {
              if (props.language === 'ua') {
                dispatch(IntlActions.setLocale('en'));
                navigate(`/`);
              } else {
                dispatch(IntlActions.setLocale('ua'));

                navigate(`/`);
              }
            }}
          />
          <StyledWriteButton
            onClick={() => props.modalSwitch(true)}
            size="large"
            style={
              screenType !== 'desktop'
                ? { position: 'fixed', top: '90%', left: 'calc(90% - 59px / 2)' }
                : { position: 'unset', marginLeft: '30px' }
            }
          >
            <img width="21px" height="21px" alt="write" src={Draw} />
          </StyledWriteButton>
        </div>
      </ToolbarStyle>
    </RootStyle>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(Navbar);
