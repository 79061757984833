import React from 'react';
import { useTranslate } from 'react-redux-multilingual';

//Material
import { Typography, Grid, Paper } from '@mui/material';
//Components
import { StyledLabel } from '../../../components/StyledComponents';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import logo from '../../../assets/logo.png';
import Vector1 from '../../../assets/WhoWeAre/Vector1.png';

export default function WhoWeAre() {
  const translate = useTranslate();

  return (
    <>
      {' '}
      <Grid item style={{ display: 'flex', marginBottom: '50px', flexDirection: 'column' }}>
        <StyledLabel style={{ margin: '0px auto 40px' }}>
          {translate('visitCard_about')}
        </StyledLabel>
        <AnimationOnScroll
          animateOnce
          offset={10}
          animateIn="animate__fadeInLeftBig"
          animateOut="animate__fadeOutLeftBig"
          duration={1}
        >
          <Paper elevation={10} className="mainPage-about-bg1">
            <div className="mainPage-about-bg1-text-wrapper">
              <img className="mainPage-about-logo" src={logo} alt="logotype" />
              <Typography className="mainPage-about-text">
                {translate('visitCard_aboutUs_1')}
              </Typography>
            </div>
          </Paper>
        </AnimationOnScroll>
      </Grid>
      <Grid item className="mainPage-about-bg2-wrapper">
        <AnimationOnScroll
          animateOnce
          offset={0}
          duration={1.5}
          animateIn="animate__fadeInRightBig"
          style={{ display: 'flex', justifyContent: 'end', width: '100%' }}
        >
          <Paper className="mainPage-about-bg2" elevation={10}>
            <div className="mainPage-about-bg1-text-wrapper">
              <img className="mainPage-about-vector" src={Vector1} alt="vector1" />
              <Typography className="mainPage-about-text">
                {translate('visitCard_aboutUs_2')}
              </Typography>
            </div>
          </Paper>
        </AnimationOnScroll>
      </Grid>
    </>
  );
}
