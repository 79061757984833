import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslate } from 'react-redux-multilingual';

//Material
import { Grid, Stack } from '@mui/material';

//Components
import Page from '../../components/Page';
import ContactModal from '../modals/ContactModal';

//Blocks
import TopBlock from './Blocks/TopBlock';
import WhatWeDo from './Blocks/WhatWeDo';
import WhoWeAre from './Blocks/WhoWeAre';
import WhyUs from './Blocks/WhyUs';
import BottomBlock from './Blocks/BottomBlock';

import './index.css';
import 'animate.css';
import CoOperation from './Blocks/CoOperation';
import AnimationBlock from './Blocks/AnimationBlock';

export default function MainPage() {
  const translate = useTranslate();
  const [isOpenContactModal, setIsOpenContactModal] = useOutletContext();

  return (
    <Page title={translate('visitCard_pageName')}>
      <>
        <ContactModal isOpenModal={isOpenContactModal} setIsOpenModal={setIsOpenContactModal} />
      </>
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <Grid
          container
          direction="column"
          style={{
            maxWidth: '1336px'
          }}
        >
          <TopBlock modalSwitch={setIsOpenContactModal} />
          <AnimationBlock />
          <WhatWeDo />
          <WhoWeAre />
          <WhyUs />
          <BottomBlock modalSwitch={setIsOpenContactModal} />
          <CoOperation />
        </Grid>
      </Stack>
    </Page>
  );
}
