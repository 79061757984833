import { Container } from '@mui/material';
import './App.css';
import RouterComponent from './routes';
import ThemeConfig from './theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  return (
    <Container
      className="mainPage-container"
      style={{
        maxWidth: '1440px',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0',
        overflow: 'hidden'
      }}
    >
      <ThemeConfig>
        <RouterComponent />
      </ThemeConfig>
    </Container>
  );
}

export default App;
