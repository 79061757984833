import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import translations from './services/translations';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual';
import { createStore, combineReducers } from 'redux';
import { Slide } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
const reducers = combineReducers(Object.assign({}, { Intl }));
const setRegion = () => {
  if (
    window.navigator.language === 'ru' ||
    window.navigator.language === 'ru-RU' ||
    window.navigator.language === 'uk-UA' ||
    window.navigator.language === 'uk'
  ) {
    return 'ua';
  } else {
    return 'en';
  }
};
const store = createStore(reducers, { Intl: { locale: setRegion() } });

root.render(
  <Provider store={store}>
    <IntlProvider translations={translations}>
      <HelmetProvider>
        <BrowserRouter>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            TransitionComponent={Slide}
            autoHideDuration={2000}
          >
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </HelmetProvider>
    </IntlProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
