import React, { useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-redux-multilingual';

//Material
import { Grid } from '@mui/material';
//Components
import { StyledLabel } from '../../../components/StyledComponents';
// PNGs

import Code from '../../../assets/AnimationBlock/Code.png';
import Build from '../../../assets/AnimationBlock/Build.png';
import Test from '../../../assets/AnimationBlock/Test.png';
import Deploy from '../../../assets/AnimationBlock/Deploy.png';
import Log from '../../../assets/AnimationBlock/Log.png';
import Monitoring from '../../../assets/AnimationBlock/Monitoring.png';
import Feedback from '../../../assets/AnimationBlock/Feedback.png';
import Track from '../../../assets/AnimationBlock/Track.png';

import CodeIllustration from '../../../assets/AnimationBlock/code_illustration.png';
import BuildIllustration from '../../../assets/AnimationBlock/build_illustration.png';
import TestIllustration from '../../../assets/AnimationBlock/test_illustration.png';
import DeployIllustration from '../../../assets/AnimationBlock/deploy_illustration.png';
import LogIllustration from '../../../assets/AnimationBlock/log_illustration.png';
import MonitoringIllustration from '../../../assets/AnimationBlock/monitoring_illustration.png';
import FeedbackIllustration from '../../../assets/AnimationBlock/feedback_illustration.png';
import TrackIllustration from '../../../assets/AnimationBlock/track_illustration.png';

import CodeUnactive from '../../../assets/AnimationBlock/default/Code_anactive.png';
import BuildUnactive from '../../../assets/AnimationBlock/default/Build_anactive.png';
import TestUnactive from '../../../assets/AnimationBlock/default/Test_anactive.png';
import DeployUnactive from '../../../assets/AnimationBlock/default/Deploy_anactive.png';
import LogUnactive from '../../../assets/AnimationBlock/default/Log_anactive.png';
import MonitoringUnactive from '../../../assets/AnimationBlock/default/Monitoring_anactive.png';
import FeedbackUnactive from '../../../assets/AnimationBlock/default/Feedback_anactive.png';
import TrackUnactive from '../../../assets/AnimationBlock/default/Track_anactive.png';

import CodeIllustrationMob from '../../../assets/AnimationBlock/IllustrationsMob/code.png';
import BuildIllustrationMob from '../../../assets/AnimationBlock/IllustrationsMob/build.png';
import TestIllustrationMob from '../../../assets/AnimationBlock/IllustrationsMob/test.png';
import DeployIllustrationMob from '../../../assets/AnimationBlock/IllustrationsMob/deploy.png';
import LogIllustrationMob from '../../../assets/AnimationBlock/IllustrationsMob/log.png';
import MonitoringIllustrationMob from '../../../assets/AnimationBlock/IllustrationsMob/monitoring.png';
import FeedbackIllustrationMob from '../../../assets/AnimationBlock/IllustrationsMob/feedback.png';
import TrackIllustrationMob from '../../../assets/AnimationBlock/IllustrationsMob/track.png';

import '../index.css';
import { Element } from 'react-scroll';
import Slider from 'react-slick';

export default function AnimationBlock() {
  const translate = useTranslate();
  const [activeStep, setActiveStep] = useState(0);
  const [stopTimer, setStopTimer] = useState(false);
  const [screenType, setScreenType] = useState('desktop');
  useEffect(() => {
    if (window.screen.width <= 920) {
      setScreenType('mobile');
    }
  }, []);
  const steps = [
    { unactive: CodeUnactive, active: Code },
    { unactive: BuildUnactive, active: Build },
    { unactive: TestUnactive, active: Test },
    { unactive: DeployUnactive, active: Deploy },
    { unactive: LogUnactive, active: Log },
    { unactive: MonitoringUnactive, active: Monitoring },
    { unactive: FeedbackUnactive, active: Feedback },
    { unactive: TrackUnactive, active: Track }
  ];
  const illustration = [
    CodeIllustration,
    BuildIllustration,
    TestIllustration,
    DeployIllustration,
    LogIllustration,
    MonitoringIllustration,
    FeedbackIllustration,
    TrackIllustration
  ];
  const interval = useRef(null);
  const autoPlay = () => {
    if (activeStep === 7) {
      setActiveStep(0);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  useEffect(() => {
    if (!stopTimer) interval.current = setTimeout(autoPlay, 2000);
    // eslint-disable-next-line
  }, [activeStep, stopTimer]);
  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: 1,
    slidesToScroll: 1,
    useCSS: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1000
    // beforeChange: (current, next) => setActiveSlide(next)a
  };
  const illustrationMob = [
    CodeIllustrationMob,
    BuildIllustrationMob,
    TestIllustrationMob,
    DeployIllustrationMob,
    LogIllustrationMob,
    MonitoringIllustrationMob,
    FeedbackIllustrationMob,
    TrackIllustrationMob
  ];
  return (
    <>
      <Grid className="mainPage-animationBlock-wrapper" item>
        <Element name="devops" />
        <StyledLabel style={{ margin: '0px auto 40px' }}>
          {translate('visitCard_animation')}
        </StyledLabel>
        <Grid
          container
          style={
            screenType === 'desktop'
              ? {
                  marginBottom: '0px',
                  margin: '0 auto',
                  marginTop: '90px',
                  width: '100%'
                }
              : {
                  marginBottom: '0px',
                  margin: '0 auto',
                  marginTop: '0px',
                  width: '100%'
                }
          }
          spacing={3}
        >
          {screenType === 'desktop' ? (
            <Grid item lg={12} className="animationBlock-wrapper">
              {steps.map((step, index) => {
                const props = Object.getOwnPropertyNames(step);

                return (
                  <img
                    onMouseEnter={() => {
                      clearTimeout(interval.current);
                      setStopTimer(true);
                      setActiveStep(index);
                    }}
                    onMouseLeave={() => {
                      setStopTimer(false);
                      setActiveStep(activeStep !== 7 ? activeStep : 0);
                    }}
                    key={index}
                    style={
                      activeStep === index
                        ? { transform: 'scale(0.314)', cursor: 'pointer' }
                        : { transform: 'scale(0.3)', cursor: 'pointer' }
                    }
                    className={`animationBlock-${
                      activeStep === index ? props[1] : props[0]
                    }${index}`}
                    src={activeStep === index ? step.active : step.unactive}
                    alt="animation"
                  />
                );
              })}
              <img
                width={320}
                alt="illustration"
                src={illustration[activeStep]}
                style={{ position: 'absolute', left: '65%', bottom: '50%' }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} style={{ padding: '0px 20px 90px' }}>
              <div
                style={
                  screenType === 'desktop'
                    ? { position: 'absolute', width: '737px', right: '-90px' }
                    : { position: 'unset', width: 'unset' }
                }
              >
                <Slider {...settings}>
                  {illustrationMob.map((el, index) => {
                    return (
                      <div key={index}>
                        <div
                          style={{ maxWidth: window.screen.width - 50 }}
                          className="slideStyleDevops"
                        >
                          <img
                            style={{
                              minWidth: '60px',
                              width: '350px',
                              height: '400px',
                              marginLeft: '30px'
                              // marginRight: '20px'
                            }}
                            src={el}
                            alt="vector"
                          />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>{' '}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
