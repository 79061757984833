import { Button, Typography, styled } from '@mui/material';

export const StyledButton = styled(Button)(({ theme, ...props }) => ({
  ...props,
  color: theme.palette.common.white,
  textTransform: 'inherit',
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '20px',
  border: 'none',
  borderRadius: '50px',
  maxWidth: 'fit-content',
  minWidth: '200px',
  padding: '30px 50px',
  '&:hover': { backgroundColor: 'transparent', boxShadow: '10px 1px 41px rgb(42 124 85 / 40%)' },
  boxShadow: '0px 7px 10px 1px rgb(145 158 171 / 30%)',
  background: 'linear-gradient(130.93deg, #36B865 23.7%, #2B894D 65.75%, #215B36 107.25%);',
  [theme.breakpoints.up('xs')]: {
    fontSize: '16px',
    minWidth: '180px',
    padding: '20px 30px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    minWidth: '190px',
    padding: '20px 30px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '24px',
    minWidth: '200px',
    padding: '30px 50px'
  }
}));
export const StyledButtonSmall = styled(Button)(({ theme, ...props }) => ({
  color: theme.palette.common.white,

  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '20px',
  border: 'none',
  borderRadius: '50px',
  maxWidth: 'fit-content',
  padding: '16px 24px',
  '&:hover': { backgroundColor: 'transparent', boxShadow: '10px 1px 41px rgb(42 124 85 / 40%)' },
  boxShadow: '0px 7px 10px 1px rgb(145 158 171 / 30%)',
  background: 'linear-gradient(130.93deg, #36B865 23.7%, #2B894D 65.75%, #215B36 107.25%);',
  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',

    padding: '12px 18px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',

    padding: '14px 20px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '20px',

    padding: '16px 24px'
  }
}));
export const StyledWriteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  // ...props,
  lineHeight: '20px',
  border: 'none',
  borderRadius: '58px',

  width: '58px !important',
  minWidth: 'unset',
  height: '58px !important',
  padding: '10px 10px',
  '&:hover': { backgroundColor: 'transparent', boxShadow: '10px 1px 41px rgb(42 124 85 / 40%)' },
  boxShadow: '0px 7px 10px 1px rgb(145 158 171 / 30%)',
  background: 'linear-gradient(130.93deg, #36B865 23.7%, #2B894D 65.75%, #215B36 107.25%);',
  [theme.breakpoints.up('xs')]: {
    padding: '10px 10px',
    width: '44px',
    height: '44px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '10px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '10px 10px'
  }
}));
export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: '700',
  fontSize: '40px',
  lineHeight: '50px !important',
  fontFamily: 'Noto Sans',
  marginBottom: '35px',
  textTransform: 'uppercase',
  [theme.breakpoints.up('xs')]: {
    fontSize: '30px',
    lineHeight: '35px',
    textAlign: 'center'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '40px',
    textAlign: 'center'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '40px',
    textAlign: 'left'
  }
}));
