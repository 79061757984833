import { errorHandler } from './errorHandler';

export default class ApiService {
  constructor(apiBase, translate) {
    this._apiBase = `${window.config.REACT_APP_API_HOST}/${apiBase}`;
    this._tanslate = translate;
  }

  getResource = async (url, query, method = 'GET', body, headers = {}) => {
    const absUrl = new URL(`${this._apiBase}${url}`);

    if (query) {
      Object.keys(query).forEach((key) => absUrl.searchParams.append(key, query[key]));
    }

    if (body) {
      body = JSON.stringify(body);
      headers['Content-Type'] = 'application/json';
    }

    const res = await fetch(absUrl, {
      method,
      body,
      headers: {
        ...headers
      }
    });

    const resData = await res.json();

    if (resData.status !== 'ok') {
      throw errorHandler(resData.info, this._tanslate);
    } else return resData;
  };

  sendRequest = async (body) => this.getResource('', null, 'POST', body);
}
