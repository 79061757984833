import React, { useEffect, useState } from 'react';

//Material
import {
  Typography,
  Modal,
  Box,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { emailRegExp, telegramRegExp } from '../../services/regularExp';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import './ContactModal.css';
import { useTranslate } from 'react-redux-multilingual';
import { useSnackbar } from 'notistack';
import { StyledLabel } from '../../components/StyledComponents';
import ApiService from '../../helpers/apiHelper';
import { LoadingButton } from '@mui/lab';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1000,
  minHeight: 500,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: '1px solid #0D7834',
  boxShadow: 24,
  p: 4
};
const StyledButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: 'Noto Sans',
  textTransform: 'uppercase',
  borderRadius: '50px',
  maxWidth: 'fit-content',
  minWidth: '150px',
  padding: '0 20px',
  '&:hover': { backgroundColor: 'transparent' },
  boxShadow: '0px 7px 10px 1px rgb(145 158 171 / 30%)',
  background:
    'linear-gradient(353.54deg, #043B1F 3.03%, #0D7834 37.41%, #0E8D3D 67.77%, #0B9552 104.82%)'
}));
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#0d7834'
  },
  '& label': {
    color: '#0d7834',
    fontFamily: 'Noto Sans'
  },
  '& .MuiInput-underline:after': {
    border: '1px #0d7834 solid'
  },
  '& .MuiInputBase-root:before': {
    borderBottom: '1px #0d7834 solid'
  },

  '& .MuiInput-root:hover:not(Mui-disabled):before': {
    borderBottom: '2px #0d7834 solid'
  },
  '& .MuiInput-root': {
    marginTop: '0'
  },
  '& .MuiInputLabel-root': {
    top: '-10px',
    fontSize: window.screen.width <= 600 && '14px'
  }
});

export default function ContactModal({ isOpenModal, setIsOpenModal, onSubmit }) {
  const [contactType, setContactType] = useState('email');
  const [isLoading, setIsLoading] = useState(false);
  const [screenType, setScreenType] = useState('desktop');
  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);

  const translate = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const api = new ApiService('send-message', translate);
  const schema = Yup.object()
    .shape({
      name: Yup.string()
        .min(2, translate('error_input_lenght_min_2'))
        .max(255, translate('error_input_lenght_max_255'))
        .required(translate('error_required')),
      subject: Yup.string()
        .min(2, translate('error_input_lenght_min_2'))
        .max(255, translate('error_input_lenght_max_255'))
        .required(translate('error_required')),

      message: Yup.string()
        .min(10, translate('error_input_lenght_min_10'))
        .max(255, translate('error_input_lenght_max_255'))
        .required(translate('error_required'))
    })
    .when((values, schema) => {
      if (contactType === 'email') {
        return schema.shape({
          contact: Yup.string()
            .matches(emailRegExp, translate('error_regExp_email'))
            .required(translate('error_required'))
        });
      }
      return schema.shape({
        contact: Yup.string()
          .matches(telegramRegExp, translate('error_regExp_telegram'))
          .required(translate('error_required'))
      });
    });
  const formik = useFormik({
    initialValues: {
      name: '',
      subject: '',
      contact: '',
      message: ''
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values, { resetForm, setErrors }) => {
      setIsLoading(true);
      const newData = {
        name: values.name,
        subject: values.subject,
        contact: values.contact,
        message: values.message
      };
      api
        .sendRequest(newData)
        .then((res) => {
          enqueueSnackbar(translate('success_sending_contacts'), { variant: 'success' });
          setTimeout(() => {
            setIsLoading(false);
            resetForm();
            setIsOpenModal(false);
          }, 1000);
        })
        .catch((error) => {
          enqueueSnackbar(translate('error_sending_contacts'), { variant: 'error' });
          setIsLoading(false);
          setErrors(error);
        });
    }
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    submitForm,
    resetForm
  } = formik;
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="visitCard_contact_modal"
      >
        <Box sx={style} className="visitCard_contact_modal-box">
          <CloseIcon
            className="visitCard_contact_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              setIsOpenModal(false);
              resetForm();
            }}
          />
          <div>
            <FormikProvider value={formik}>
              <Form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
                style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
              >
                <Grid container spacing={screenType !== 'desktop' ? 1 : 2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <StyledLabel className="contactModal-title-text">
                      {translate('visitCard_contact_modal')}
                    </StyledLabel>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography className="contactModal-main-text" style={{ marginBottom: '30px' }}>
                      {translate('visitCard_contact_modal_l')}
                    </Typography>
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        style={{ color: 'black', marginBottom: '10px', fontFamily: 'Noto Sans' }}
                      >
                        {translate('visitCard_contact_modal_inputType')}
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={contactType}
                        onChange={(e) => {
                          if (e.target.value === 'email') {
                            setFieldValue('contact', '');
                          } else {
                            setFieldValue('contact', '');
                          }
                          setContactType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="email"
                          control={<Radio style={{ color: '#0d7834' }} />}
                          label={translate('visitCard_contact_modal_inputType_email')}
                        />
                        <FormControlLabel
                          value="telegram"
                          control={<Radio style={{ color: '#0d7834' }} />}
                          label={translate('visitCard_contact_modal_inputType_telegram')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Grid item xs={12} className="contactModal-inputs-first">
                      <CssTextField
                        label={translate('visitCard_contact_modal_input_name')}
                        variant="standard"
                        fullWidth
                        size="normal"
                        inputProps={{ style: { textAlign: 'center' } }}
                        error={Boolean(touched.name && errors.name)}
                        {...getFieldProps('name')}
                        value={values.name}
                      >
                        {values.name}
                      </CssTextField>
                      {touched.name && errors.name && (
                        <div style={{ color: 'red', margin: '5px 0px', fontSize: '12px' }}>
                          {errors.name}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                      <CssTextField
                        color="grey"
                        fullWidth
                        size="normal"
                        inputProps={{ style: { textAlign: 'center' } }}
                        error={Boolean(touched.subject && errors.subject)}
                        {...getFieldProps('subject')}
                        value={values.subject}
                        label={translate('visitCard_contact_modal_input_subject')}
                        variant="standard"
                      >
                        {values.subject}
                      </CssTextField>
                      {touched.subject && errors.subject && (
                        <div style={{ color: 'red', margin: '5px 0px', fontSize: '12px' }}>
                          {errors.subject}
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                      <CssTextField
                        color="grey"
                        fullWidth
                        size="normal"
                        inputProps={{ style: { textAlign: 'center' } }}
                        error={Boolean(touched.contact && errors.contact)}
                        {...getFieldProps('contact')}
                        value={values.contact}
                        label={
                          contactType === 'email'
                            ? translate('visitCard_contact_modal_input_email')
                            : translate('visitCard_contact_modal_input_telegram')
                        }
                        variant="standard"
                      >
                        {values.contact}
                      </CssTextField>
                      {touched.contact && errors.contact && (
                        <div style={{ color: 'red', margin: '5px 0px', fontSize: '12px' }}>
                          {errors.contact}
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                      <CssTextField
                        color="grey"
                        fullWidth
                        size="normal"
                        inputProps={{ style: { textAlign: 'center' } }}
                        error={Boolean(touched.message && errors.message)}
                        {...getFieldProps('message')}
                        value={values.message}
                        label={translate('visitCard_contact_modal_input_message')}
                        variant="standard"
                      >
                        {values.message}
                      </CssTextField>
                      {touched.message && errors.message && (
                        <div style={{ color: 'red', margin: '5px 0px', fontSize: '12px' }}>
                          {errors.message}
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                  >
                    <StyledButton size="large" onClick={submitForm} loading={isLoading}>
                      {translate('visitCard_contact_modal_button_send')}
                    </StyledButton>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
