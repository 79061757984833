import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { Element } from 'react-scroll';

//Material
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
//Components
import { StyledLabel } from '../../../components/StyledComponents';
import { useState } from 'react';
import { useEffect } from 'react';

export default function BottomBlock({ modalSwitch }) {
  const translate = useTranslate();
  const [overflow, setOverflow] = useState(false);
  const [scrollItemsCount, setScrollItemsCount] = useState(0);
  const [screenType, setScreenType] = useState('desktop');

  useEffect(() => {
    const element = document.getElementById('questionsBlock');

    setScrollItemsCount(element.childElementCount);
  }, []);
  useEffect(() => {
    if (window.screen.width <= 900) {
      setScreenType('mobile');
    }
  }, []);
  const isOverflow = () => {
    const element = document.getElementById('questionsBlock');

    if (element) {
      if (element.scrollHeight > element.clientHeight) {
        setOverflow(true);
      } else setOverflow(false);
    }
  };

  const scrollElementHeight = () => {
    if (scrollItemsCount > 5 && screenType !== 'mobile') {
      return '495px';
    } else if (scrollItemsCount > 5 && screenType === 'mobile') {
      return '460px';
    }
    return '';
  };

  return (
    <Grid className="mainPage-FAQ-container" item>
      <Element name="FAQ" />

      <Grid container spacing={2}>
        <Grid item xs={12} lg={3} className="mainPage-FAQ-container-top">
          <StyledLabel style={{ textTransform: 'capitalize' }}>
            {translate('visitCard_FAQ')}
          </StyledLabel>
          <Typography className="mainPage-FAQ-text">{translate('visitCard_FAQ_l')}</Typography>
        </Grid>

        <Grid
          item
          xs={overflow || scrollItemsCount > 5 ? 11.4 : 12}
          lg={8.5}
          sx={{ mb: 5 }}
          style={{
            maxHeight: scrollElementHeight(),
            paddingRight: overflow || scrollItemsCount > 5 ? '0px' : '0px'
          }}
          className="mainPage-FAQ-accordion-wrapper"
          id="questionsBlock"
        >
          <Accordion>
            <AccordionSummary
              onClick={() => setTimeout(() => isOverflow(), 200)}
              expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: '#0D7834' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="mainPage-FAQ-accordion"
            >
              <Typography className="mainPage-FAQ-text-exact">
                {translate('visitCard_FAQ_question_1')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="mainPage-FAQ-answer">
                {translate('visitCard_FAQ_answer_1')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              onClick={() => setTimeout(() => isOverflow(), 500)}
              expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: '#0D7834' }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className="mainPage-FAQ-accordion"
            >
              <Typography className="mainPage-FAQ-text-exact">
                {translate('visitCard_FAQ_question_2')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="mainPage-FAQ-answer">
                {translate('visitCard_FAQ_answer_2')}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              onClick={() => setTimeout(() => isOverflow(), 500)}
              expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: '#0D7834' }} />}
              aria-controls="panel3a-content"
              className="mainPage-FAQ-accordion"
              id="panel3a-header"
            >
              <Typography className="mainPage-FAQ-text-exact">
                {translate('visitCard_FAQ_question_3')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="mainPage-FAQ-answer">
                {translate('visitCard_FAQ_answer_3')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              onClick={() => setTimeout(() => isOverflow(), 500)}
              expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: '#0D7834' }} />}
              aria-controls="panel3a-content"
              className="mainPage-FAQ-accordion"
              id="panel3a-header"
            >
              <Typography className="mainPage-FAQ-text-exact">
                {translate('visitCard_FAQ_question_4')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="mainPage-FAQ-answer" sx={{ whiteSpace: 'break-spaces' }}>
                {translate('visitCard_FAQ_answer_4')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              onClick={() => setTimeout(() => isOverflow(), 500)}
              expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: '#0D7834' }} />}
              aria-controls="panel3a-content"
              className="mainPage-FAQ-accordion"
              id="panel3a-header"
            >
              <Typography className="mainPage-FAQ-text-exact">
                {translate('visitCard_FAQ_question_5')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="mainPage-FAQ-answer" sx={{ whiteSpace: 'break-spaces' }}>
                {translate('visitCard_FAQ_answer_5')}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {(overflow || scrollItemsCount > 5) && (
          <Grid item xs={0.6} lg={0.4}>
            <div
              style={{
                width: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                height: screenType === 'mobile' ? '450px' : '485px'
              }}
            >
              <div className="mainPage-FAQ-overflow-marker-2">
                <ArrowUpwardIcon fontSize="medium" style={{ color: '#0D7834' }} />
              </div>
              <div className="mainPage-FAQ-overflow-marker">
                <ArrowDownwardIcon fontSize="medium" style={{ color: '#0D7834' }} />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
