import { Outlet, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import Navbar from './Header/Navbar';
import Footer from './Footer/Footer';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { IntlActions } from 'react-redux-multilingual';
import './index.css';
// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%'
});

// ----------------------------------------------------------------------

function MainLayout() {
  const params = useParams();
  const navigate = useNavigate();
  const { lng } = params;

  const [isOpenContactModal, setIsOpenContactModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (lng === 'ua' || lng === 'en') {
      dispatch(IntlActions.setLocale(lng));
    } else navigate('/');
    // eslint-disable-next-line
  }, [lng]);

  return (
    <RootStyle className="layout-root">
      <div className="layout-root-cover">
        <Navbar modalSwitch={setIsOpenContactModal} />
        <Outlet context={[isOpenContactModal, setIsOpenContactModal]} />
        <Footer />
      </div>
    </RootStyle>
  );
}

export default MainLayout;
