import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { Element } from 'react-scroll';

//Material
import { Grid } from '@mui/material';

//Components
import { StyledLabel } from '../../../components/StyledComponents';

import { useParams } from 'react-router-dom';
import CoOperationEn from '../../../assets/stages_pic_en.png';
import CoOperationEnMob from '../../../assets/stages_pic_en_mob.png';
import CoOperationUa from '../../../assets/stages_pic_ua.png';
import CoOperationUaMob from '../../../assets/stages_pic_ua_mob.png';
export default function CoOperation() {
  const translate = useTranslate();
  const params = useParams();

  const { lng } = params;
  const [screenType, setScreenType] = useState('desktop');
  useEffect(() => {
    if (window.screen.width <= 900) {
      setScreenType('mobile');
    }
  }, []);

  return (
    <Grid item className="mainPage-CoOperation-container">
      <Element name="Cooperation" />
      <StyledLabel style={{ textAlign: 'center', margin: '0' }}>
        {translate('visitCard_CoOperation')}
      </StyledLabel>
      {screenType === 'desktop' ? (
        <img
          style={{ margin: '70px 0px', width: '100%' }}
          src={lng === 'ua' ? CoOperationUa : CoOperationEn}
          alt="cooperation"
        />
      ) : (
        <img
          style={{ margin: '70px auto', width: '100%' }}
          src={lng === 'ua' ? CoOperationUaMob : CoOperationEnMob}
          alt="cooperation"
        />
      )}
    </Grid>
  );
}
