import styled from '@emotion/styled';
import { TabList } from '@mui/lab';

export const StyledSubTabs = styled(TabList)`
  .MuiButtonBase-root.MuiTab-root {
    position: absolute !important;
    // background: white;
    border-radius: 15px 15px 0 0;
    max-width: 313.99px;
    min-width: 313.99px !important;
    max-height: 79px;
    min-height: 79px;

    // :hover {
    //   background: pink;
    // }
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    position: relative !important;

    border-bottom: none; /* not working */
    z-index: 0 !important;
    background-color: white;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    word-wrap: brack-word;
    width: fit-content !important;

    /* main green */

    color: #0d7834;
  }
  .MuiButtonBase-root.MuiTab-root {
    text-align: left;
    position: relative !important;
    // padding: 10px;
    width: fit-content !important;
    z-index: 0 !important;
    margin: 0 !important;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;

    color: #000000;

    // :hover {
    //   background-color: pink;
    // }
  }

  .MuiTabs-indicator {
    display: none;
  }
`;
