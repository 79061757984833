import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { Element } from 'react-scroll';

//Material
import { Container, Typography } from '@mui/material';
//Components
import { StyledButton } from '../../../components/StyledComponents';
export default function TopBlock({ modalSwitch }) {
  const translate = useTranslate();

  return (
    <Container className="mainPage-title-container">
      <Element name="top" />
      <Typography variant="h1" className="mainPage-title">
        {translate('visitCard_slogun')}
      </Typography>
      <Typography variant="subtitle" className="mainPage-subtitle">
        {translate('visitCard_slogun_sub')}
      </Typography>
      <StyledButton size="large" onClick={() => modalSwitch(true)}>
        {translate('visitCard_contact_button')}
      </StyledButton>
      <Element name="services" />
    </Container>
  );
}
