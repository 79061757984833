import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { Element } from 'react-scroll';
import Slider from 'react-slick';
//Material
import { Grid, Paper, Typography } from '@mui/material';

import vector1 from '../../../assets/WhatWeDo/Vector1.png';
import vector2 from '../../../assets/WhatWeDo/Vector2.png';
import vector3 from '../../../assets/WhatWeDo/Vector3.png';
import vector4 from '../../../assets/WhatWeDo/Vector4.png';
import vector5 from '../../../assets/WhatWeDo/Vector5.png';

//Components
import { StyledLabel } from '../../../components/StyledComponents';
import { useState } from 'react';
import { useEffect } from 'react';

export default function WhatWeDo() {
  const [showDesc, setShowDesc] = useState(false);
  const [actualDesc, setActualDesc] = useState('');

  const [screenType, setScreenType] = useState('desktop');
  // const [activeSlide, setActiveSlide] = useState(0);
  const translate = useTranslate();

  const settings = {
    dots: screenType !== 'desktop' ? true : false,
    infinite: true,
    speed: 300,
    slidesToShow: screenType !== 'desktop' ? 1 : 3,
    slidesToScroll: 1,
    useCSS: true,
    arrows: screenType !== 'desktop' ? false : true

    // beforeChange: (current, next) => setActiveSlide(next)a
  };
  useEffect(() => {
    if (window.screen.width <= 1024) {
      setScreenType('mobile');
    }
  }, []);

  const cards = [
    {
      vector: vector1,
      title: translate('visitCard_expretice_theme_1'),
      desc: translate('visitCard_expretice_desc_1')
    },
    {
      vector: vector2,
      title: translate('visitCard_expretice_theme_2'),
      desc: translate('visitCard_expretice_desc_2')
    },
    {
      vector: vector3,
      title: translate('visitCard_expretice_theme_3'),
      desc: translate('visitCard_expretice_desc_3')
    },
    {
      vector: vector4,
      title: translate('visitCard_expretice_theme_4'),
      desc: translate('visitCard_expretice_desc_4')
    },
    {
      vector: vector5,
      title: translate('visitCard_expretice_theme_5'),
      desc: translate('visitCard_expretice_desc_5')
    }
  ];
  const handleOpenDesc = (desc, theme, index) => {
    setShowDesc(true);
    setActualDesc(desc);
  };
  const handleCloseDesc = () => {
    setShowDesc(false);
    setActualDesc('');
  };

  return (
    <Grid className="mainPage-expertice-paper-container" container spacing={3}>
      <Grid
        item
        xs={screenType === 'desktop' ? 5 : 12}
        style={
          screenType === 'desktop'
            ? { maxWidth: '600px', height: '300px' }
            : { height: 'fit-content', padding: '0px 20px' }
        }
      >
        <StyledLabel>{translate('visitCard_expretice')}</StyledLabel>
      </Grid>

      <Grid
        item
        xs={screenType === 'desktop' ? 6 : 12}
        style={
          screenType === 'desktop'
            ? { position: 'relative' }
            : { position: 'relative', padding: '0px 20px' }
        }
      >
        <div
          style={
            screenType === 'desktop'
              ? { position: 'absolute', width: '737px', right: '-80px' }
              : { position: 'unset', width: 'unset', maxWidth: '500px', margin: '0 auto' }
          }
        >
          <Element name="services" />
          <Slider {...settings}>
            {cards.map((el, index) => {
              return (
                <div key={index}>
                  <div
                    style={{ cursor: 'pointer' }}
                    className="slideStyle"
                    onMouseEnter={() => {
                      handleOpenDesc(el.desc, screenType === 'mobile' && index + 1, index);
                    }}
                    onMouseLeave={handleCloseDesc}
                  >
                    <img
                      style={{
                        minWidth: '60px',
                        width: '60px',
                        height: '60px',
                        marginLeft: '20px',
                        marginRight: '20px'
                      }}
                      src={el.vector}
                      alt="vector"
                    />

                    <Typography className="mainPage-expertice-papers-title">{el.title}</Typography>
                    <Element name="about" />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        {screenType === 'desktop' && showDesc && (
          <Paper
            className="mainPage-expertice-item-popover"
            style={{
              left: '-29%'
            }}
          >
            <Typography
              style={{
                textAlign: 'left',
                margin: '0 auto',
                whiteSpace: 'pre-line',
                fontSize: '20px'
              }}
            >
              {actualDesc}
            </Typography>
          </Paper>
        )}
        {screenType === 'mobile' && showDesc && (
          <Paper
            className="mainPage-expertice-item-popover"
            style={{
              left: '30%',
              top: '94%'
            }}
          >
            <Typography
              style={{
                textAlign: 'left',
                margin: '0 auto',
                whiteSpace: 'pre-line',
                fontSize: '20px'
              }}
            >
              {actualDesc}
            </Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
