import React from 'react';
import './index.css';

const LangSwitch = ({ isOn, handleToggle, onColor, ...props }) => {
  return (
    <label {...props} className="react-switch-label">
      <input
        checked={isOn}
        onChange={(e) => {
          handleToggle();
        }}
        className="react-switch-checkbox"
        type="checkbox"
      />
      <button className="react-switch-switcher" />
      <div className="react-switch-labels">
        <span>UA</span>
        <span>EN</span>
      </div>
    </label>
  );
};

export default LangSwitch;
