import { Navigate, useRoutes } from 'react-router-dom';
import React from 'react';
//Pages
import MainPage from './pages/MainPage';

import MainLayout from './layouts';
import { connect } from 'react-redux';

// ----------------------------------------------------------------------

function Router({ language }) {
  return useRoutes([
    { path: '/', element: <Navigate to={'/' + language} replace exact /> },
    {
      path: '/:lng',
      element: <MainLayout />,
      children: [{ path: '/:lng/', element: <MainPage /> }]
    }
  ]);
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(Router);
